import { CalendarOutlined, FileTextOutlined, LoadingOutlined } from '@ant-design/icons';
import { Form, Row, Select, Col, Button, Table, Result, Spin, Tooltip, List, Card, Descriptions, Avatar, } from 'antd';
import React, { useEffect, useRef, useState } from 'react'
import { useStoreActions, useStoreState } from '../../store/hooks/easyPeasy';
import { SelectAcademicYear } from '../profile/SelectAcademicYear';
import ReactToPrint from 'react-to-print';

declare module 'react' {
    interface HTMLAttributes<T> extends AriaAttributes, DOMAttributes<T> {
        // extends React's HTMLAttributes
        name?: string;
    }
}

const ComponentToPrint = React.forwardRef((props: any, ref: any) => {
    const { data, info } = props;

    return (
        <div className='print-source'>
            <div ref={ref} style={{ padding: 20, display: "flex", flexDirection: "column" }}>
                <style>
                    {`
                .landscape-container {
                    display: flex;
                    flex-wrap: wrap;
                    justify-content: space-between;
                    padding: 5px;
                    width: 100%;
                    box-sizing: border-box;
                    font-size: .55rem
                    
                }
                .section {
                    width: 24.6%;
                    border: 1px solid #000;
                    padding: 5px;
                    }
                .header {
                    text-align: center;
                    margin-bottom: 10px;
                }
                .header h2, .header h3 {
                    margin: 0;
                }
                .details {
                    margin-bottom: 10px;
                }
                .details label {
                    font-weight: bold;
                    display: block;
                    margin-bottom: 5px;
                }
                .details .static-value {
                    border: 1px solid #000;
                    padding: 5px;
                    background: #f0f0f0;
                }
                .fees-table {
                    width: 100%;
                    border-collapse: collapse;
                    margin-top: 10px;
                }
                .fees-table th, .fees-table td {
                    border: 1px solid #000;
                    padding: 5px;
                    text-align: left;
                }
                .total {
                    font-weight: bold;
                    text-align: right;
                }
                `}
                </style>

                <div className="landscape-container">
                    {Array(4).fill(null).map((_, index) => (
                        <div className="section" key={index}>
                            <div className="header">
                                <img
                                    src={info?.logoLink}
                                    alt="Institute Logo"
                                    style={{ height: 50, marginBottom: 10 }}
                                />
                                <h2>{info?.instituteName}</h2>
                                <h3>{info?.instituteAddress}</h3>
                                <h3>Fees Report</h3>
                            </div>

                            <div className="details">
                                <label>Account ID:</label>
                                <div className="static-value">{data?.accountId}</div>

                                <label>Account Name:</label>
                                <div className="static-value">{data?.accountName}</div>

                                <label>Class:</label>
                                <div className="static-value">{data?.className}</div>

                                <label>Roll:</label>
                                <div className="static-value">{data?.accountRoll}</div>

                                <label>Transaction Date:</label>
                                <div className="static-value">{data?.transactionDate}</div>

                                <label>Month:</label>
                                <div className="static-value">{data?.monthName}</div>
                            </div>

                            <table className="fees-table">
                                <thead>
                                    <tr>
                                        <th>Fee Details</th>
                                        <th>Amount</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {data?.feesDetails?.split(",").map((feeDetail, idx) => {
                                        const [name, amount] = feeDetail?.split(" - ");
                                        return (
                                            <tr key={idx}>
                                                <td>{name?.trim()}</td>
                                                <td>{amount?.trim()}</td>
                                            </tr>
                                        );
                                    })}
                                    <tr>
                                        <td className="total">Total</td>
                                        <td className="total">{data?.feesAmount}</td>
                                    </tr>
                                </tbody>
                            </table>

                            <div style={{ marginTop: 10, textAlign: "center" }}>
                                <p>
                                    {{
                                        0: "Parent/Student Copy",
                                        1: "Class Teacher Copy",
                                        2: "Office Copy",
                                        3: "Bank Copy",
                                    }[index] || ""}
                                </p>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
});



const ComponentToPrintWrapper = ({ data, info }) => { // 1.
    const componentRef: any = useRef(); // 2.

    return (
        <div style={{ display: "flex" }}>
            <ReactToPrint
                trigger={() => <Button type='primary' icon={<FileTextOutlined />}>Print</Button>}
                content={() => componentRef.current}
            />
            <ComponentToPrint ref={componentRef} data={data} info={info} />
        </div>
    );
};
export default function ReportPremier(props) {

    const user = useStoreState(state => state.auth.user)
    const loading = useStoreState(state => state.auth.loading)
    const premierBankSslFeesTransactionList = useStoreState(state => state.auth.premierBankSslFeesTransactionList)
    const fetchpremierBankSslFeesTransactionList = useStoreActions(state => state.auth.fetchpremierBankSslFeesTransactionList);

    const fetchacademicYearList = useStoreActions((state) => state.auth.fetchacademicYearList);
    useEffect(() => {
        fetchacademicYearList(user?.instituteId)
    }, [])


    const columns: any = [
        {
            title: 'Account Id',
            dataIndex: 'accountId',
            key: 'accountId',
        },
        {
            title: 'Account Name',
            dataIndex: 'accountName',
            key: 'accountName',
        },
        {
            title: 'Father Name',
            dataIndex: 'fatherName',
            key: 'fatherName',
        },
        {
            title: 'Class',
            dataIndex: 'className',
            key: 'className',
        },
        {
            title: 'Roll',
            dataIndex: 'accountRoll',
            key: 'accountRoll',
        },
        {
            title: 'Month',
            dataIndex: 'monthName',
            key: 'monthName',
        },
        {
            title: 'Fees Amount',
            dataIndex: 'feesAmount',
            key: 'feesAmount',
        },
        {
            title: 'Service Charge',
            dataIndex: 'serviceCharge',
            key: 'serviceCharge',
        },
        {
            title: 'Total Amount',
            dataIndex: 'amount',
            key: 'amount',
        },
        {
            title: 'Bank Transaction Id',
            dataIndex: 'bankTransactionId',
            key: 'bankTransactionId',
        },
        {
            title: 'Transaction Date',
            dataIndex: 'transactionDate',
            key: 'transactionDate',
        },

        {
            title: 'Print',
            fixed: 'right',
            render: (index: any, record: any) => (

                <Tooltip title={"Print"}>
                    <ComponentToPrintWrapper key={index} data={record} info={user} />
                </Tooltip>

            ),
        },
    ];

    const antIcon = <LoadingOutlined style={{ fontSize: 50 }} spin />;

    //console.log(premierBankSslFeesTransactionList)
    var isMobile = false; //initiate as false
    if (
        /iP(hone|od)|android.+mobile|BlackBerry|IEMobile/i.test(navigator.userAgent)
    ) {
        isMobile = true;
    }
    const [form] = Form.useForm();

    const onFinish = (values: any) => {
        let payload: any = {
            paymentYear: values?.year,
            instituteId: user?.instituteId,
            customStudentId: user?.customStudentId,
        }
        fetchpremierBankSslFeesTransactionList(payload)
    }


    return (
        <div className="mt-25 mb-sm-25">
            <Card title="Fee Report" className='custom-card-view'>
                <Form layout="vertical" onFinish={onFinish} id='create-class' form={form} className="mb-sm-25" >
                    <Row gutter={15}>
                        <Col xs={24} sm={24} md={24} lg={4} xl={4}></Col>
                        <Col xs={24} sm={24} md={24} lg={8} xl={8}>

                            <Form.Item
                                name="year"
                                label="Year:"
                                className="title-Text custon-form-wrapper"
                                rules={[
                                    { required: true, message: "Please Select Year" },
                                ]}
                            >
                                <SelectAcademicYear />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={8} xl={8}>
                            <Form.Item className="mb-sm-0">
                                <Button type="primary" className="success-button" htmlType="submit" style={{ marginTop: isMobile ? 0 : 30, width: "100%", padding: 10 }}>
                                    Search
                                </Button>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>

            </Card>
            <div className="loading" style={{ display: loading ? "inherit" : "none" }}> <Spin indicator={antIcon} /></div>
            {premierBankSslFeesTransactionList.length > 0 &&
                <>
                    <Table bordered={true} dataSource={premierBankSslFeesTransactionList} columns={columns} className="custom-table" scroll={{ x: 1024 }} pagination={{ defaultPageSize: 10, showSizeChanger: true, pageSizeOptions: ['10', '20', '30'] }} />

                </>
            }
        </div>
    )
}
